$(document).ready(function() {

    //vars
    var gallery = $('.gallery-js, [id^="gallery-"]'),
        slideCount = null,
        galleryCount = $('.gallery-counter-js'),
        galleryContainer = $('.gallery-container-js');
    //end vars

    
    //menu xs
    if ($('.button-menu').length) {
        $('.button-menu').on('click', function (e) {
            e.preventDefault();
            $(this).toggleClass('active');
            $('html').toggleClass('body-menu-active');
        });
    }
    //end menu xs


    //scroll to
    //console.log('c');
    // $('a[href*="#"]:not([href="#"])').on('click', function (e) {
    //     if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
    //         var target = $(this.hash),
    //             hash;
    //         target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
    //         hash = '#' + target.attr('id');
    //         if (target.length) {
    //             $('html, body').animate({
    //                 scrollTop: target.offset().top -200 //-80px
    //             }, 500);
    //             history.replaceState(null, null, hash);
    //         }
    //         return false;
    //     }
    // });
    //end scroll to


    //add class header on scroll
    function add_class_scroll() {
        var header__bg = $('.header--js'),
            scroll = $(window).scrollTop();
        if (scroll >= 1) {
            header__bg.addClass('bg')
        } else {
            header__bg.removeClass('bg')
        }
    }
    $(window).on('scroll', add_class_scroll);
    add_class_scroll();
    //end add class header on scroll
    

    //story page - gallery
    if (gallery.length) {
        function initslick(itemgalleryIndex = 0) {

            gallery.each( function() {
                // console.log('gallery:', gallery);
            });

            gallery.on('init', function(event, slick){
                slideCount = slick.slideCount;
                setSlideCount();
                setCurrentSlideNumber(slick.currentSlide);
            });
        
            gallery.on('beforeChange', function(event, slick, currentSlide, nextSlide){
                setCurrentSlideNumber(nextSlide);
            });
        
            function setSlideCount() {
                var el = galleryCount.find('.total');
                el.text(slideCount);
            }
        
            function setCurrentSlideNumber(currentSlide) {
                var el = galleryCount.find('.current');
                el.text(currentSlide + 1);
            }

            gallery.slick({
                infinite: false,
                fade: true,
                arrows: true,
                adaptiveHeight: true,
                initialSlide: itemgalleryIndex,
                prevArrow: '<button type="button" class="btn-carousel-js btn-carousel btn-carousel-prev"><i class="fas fa-arrow-left"></i></button>',
                nextArrow: '<button type="button" class="btn-carousel-js btn-carousel btn-carousel-next"><i class="fas fa-arrow-right"></i></button>'
            });
        
        }

        function fullscreengallery(itemgalleryIndex = 0) {
            initslick(itemgalleryIndex);
            galleryCount.show();
            galleryContainer.addClass('gallery-full');
            $('html').addClass('body-menu-active');
        }

        //item gallery grid - fullscreen
        $('.project-details').on('click', '.gallery-grid .item-gallery-js', function(e) {
            if(!galleryContainer.hasClass('gallery-full')) {
                fullscreengallery($(this).index());
            }
        });


        //init slick
        initslick();
        
        
        //gallery - fullscreen
        $('.fullscreen-js').on('click', function(e) {
            if(galleryContainer.hasClass('gallery-normal')) {
                galleryContainer.addClass('gallery-full');
                $('html').addClass('body-menu-active');
                gallery.slick('resize');
            } else {
                gallery.slick('destroy');
                fullscreengallery();
            }
        });


        //gallery - exit fullscreen
        $('.exit-fullscreen-js').on('click', function(e) {
            if (galleryContainer.hasClass('gallery-grid')) {
                gallery.slick('destroy');
                $('.item-gallery-js').removeAttr('style');
                galleryCount.hide();
            } else {
                gallery.slick('destroy');
                initslick();
                $('body').removeClass('overlayer');
            }
            galleryContainer.removeClass('gallery-full');
            $('html').removeClass('body-menu-active');
        });

        
        //gallery - schimbare din normal in grid view
        $('.grid-view-js').on('click', function(e) {
            galleryContainer.removeClass('gallery-normal').addClass('gallery-grid');
            gallery.slick('destroy');
            $('html, body').stop().animate({
                'scrollTop': galleryContainer.offset().top - 75
            });
            galleryCount.hide();
        });


        //gallery - schimbare din grid in normal view
        $('.normal-view-js').on('click', function(e) {
            galleryContainer.removeClass('gallery-grid').addClass('gallery-normal');
            initslick();
            $('html, body').stop().animate({
                'scrollTop': galleryContainer.offset().top - 75
            });
            galleryCount.show();
        });
        

        //overlayer la click pe sageti
        setTimeout(function() {
            $('.gallery--project-js .btn-carousel-js').on('click', function(e) {
                $('body').addClass('overlayer');
                //console.log($('body').addClass('overlayer'));
            });
        }, 1);

        //overlayer remove la scroll
        $(window).on('scroll', function(e) {
            $('body').removeClass('overlayer');
        });

    }
    //end story page - gallery
    

});